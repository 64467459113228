import * as React from 'react'
import { useEffect, useTransition } from 'react'
import {
  addCartItem,
  getOrCreateCart,
  removeCartItem,
} from '../../actions/cart.actions'
import { useCartStore } from './cart-store'
import { PlatformCart } from '../../shopify/types'

interface StoreContextType {
  isOpen: boolean
  closeCart: () => void
  openCart: () => void
  addVariantToCart: (variantId: string) => Promise<void>
  removeLineItem: (variantId: string) => Promise<void>
  cart: PlatformCart | null
  loading: boolean
}

export const StoreContext = React.createContext<StoreContextType>({
  cart: {
    id: '',
    checkoutUrl: '',
    cost: {
      subtotalAmount: undefined,
      totalAmount: undefined,
      totalTaxAmount: undefined,
    },
    items: [],
    totalQuantity: 0,
  },
  isOpen: false,
  loading: false,
  closeCart: () => {},
  openCart: () => {},
  addVariantToCart: (id) => Promise.resolve(),
  removeLineItem: (id) => Promise.resolve(),
} as StoreContextType)

interface StoreProviderProps {
  children: React.ReactNode
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [isPending, startTransition] = useTransition()
  const isOpen = useCartStore((s) => s.isOpen)
  const openCart = useCartStore((s) => s.openCart)
  const closeCart = useCartStore((s) => s.closeCart)
  const setCart = useCartStore((s) => s.setCart)
  const cart = useCartStore((s) => s.cart)
  const lastUpdatedAt = useCartStore((s) => s.lastUpdatedAt)
  const refresh = useCartStore((s) => s.refresh)

  const addVariantToCart = async (variantId: string): Promise<void> => {
    setLoading(true)
    try {
      await addCartItem(variantId).then(() => {
        openCart()
      })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const removeLineItem = async (variantId: string): Promise<void> => {
    setLoading(true)
    try {
      const ok = await removeCartItem(variantId)
      if (ok) {
        refresh()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => setLoading(false), 2000)
    }
  }

  useEffect(() => {
    startTransition(async () => {
      const { cart } = await getOrCreateCart()
      cart && setCart(cart)
      // when lastUpdatedAt changes, we need to update the cart
      // console.log(cart)
    })
  }, [lastUpdatedAt, setCart])

  return (
    <StoreContext.Provider
      value={{
        isOpen,
        closeCart,
        openCart,
        cart,
        loading,
        addVariantToCart,
        removeLineItem,
      }}>
      {children}
    </StoreContext.Provider>
  )
}
