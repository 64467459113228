import { PlatformCart } from '../../shopify/types'
import { create } from 'zustand'

export interface CartStore {
  isOpen: boolean
  lastUpdatedAt: number
  cart: PlatformCart | null
  checkoutReady: boolean

  openCart: () => void
  closeCart: () => void
  refresh: () => void
  setCart: (payload: PlatformCart | null) => void
  setCheckoutReady: (payload: boolean) => void
}

export const useCartStore = create<CartStore>()((set) => ({
  isOpen: false,
  lastUpdatedAt: 0,
  cart: {
    id: '',
    checkoutUrl: '',
    items: [],
    cost: {
      subtotalAmount: undefined,
      totalAmount: undefined,
      totalTaxAmount: undefined,
    },
    totalQuantity: 0,
  },
  checkoutReady: true,

  openCart: () =>
    set(() => ({
      isOpen: true,
      lastUpdatedAt: Date.now(),
    })),
  closeCart: () =>
    set(() => ({
      isOpen: false,
      lastUpdatedAt: Date.now(),
    })),
  refresh: () => set(() => ({ lastUpdatedAt: Date.now() })),
  setCheckoutReady: (payload: boolean) =>
    set(() => ({ checkoutReady: payload })),
  setCart: (payload: PlatformCart | null) => set(() => ({ cart: payload })),
}))
