import * as React from 'react'
import { StoreContext } from '../../contexts/store/StoreContext'

const LineItem = (props) => {
  const { line_item } = props

  const { removeLineItem } = React.useContext(StoreContext)

  const image = line_item.merchandise.image
  const imageItem = image ? (
    <figure className="image is-96x96">
      <img decoding="async" src={image.url} alt={image.altText || null} />
    </figure>
  ) : null

  const removeItem = (e) => {
    const lineToDelete = e.target.closest('tr')
    lineToDelete.classList.add('opaque')
    removeLineItem(line_item.id)
  }

  return (
    <tr>
      <th>
        <div className="columns">
          <hr />
          <div className="column">{imageItem}</div>
          <div className="column">
            <p className="product-title">
              {line_item.merchandise.product.title}
            </p>

            {line_item.merchandise.title !== 'Default Title' && (
              <p className="product-desc">{line_item.merchandise.title}</p>
            )}
          </div>
        </div>
      </th>
      <th align="center">{line_item.quantity}</th>
      <th>{`$${Number(line_item.merchandise.price.amount).toFixed(2)}`}</th>
      <th>
        {`$${(line_item.quantity * Number(line_item.cost.totalAmount.amount)).toFixed(2)}`}
      </th>
      <th>
        <p
          className="has-text-weight-normal delete"
          onClick={(e) => removeItem(e)}>
          Delete
        </p>
      </th>
    </tr>
  )
}

export default LineItem
