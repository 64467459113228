import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby' /* eslint-disable */
import Subscribe from '../subscribe'

const MobileMenu = ({ path, open, setOpen }) => {
  return (
    <StyledMobileMenu open={open}>
      <div
        className={open ? 'closeMenuArea is-visible' : 'closeMenuArea'}
        onClick={() => setOpen(false)}></div>

      <div className="menuContainer">
        <div className="filter-container">
          <div className={path == '' ? 'filter-item active' : 'filter-item'}>
            <Link to={`/`} onClick={() => setOpen(false)}>
              Shop
            </Link>
          </div>
        </div>

        <Link
          aria-label="info"
          to={`/info/`}
          onClick={(e) => setOpen(false)}
          className={path == 'info' ? 'active' : ''}>
          Info
        </Link>

        {/* <p className="spacer"></p> */}
        <p className="spacer"></p>
        <div className="contact-group">
          <p>SAY HELLO</p>
          <p className="contact-item">
            <a className="lowercase" href="mailto:hello@emiledrescher.com">
              hello@emiledrescher.com
            </a>
          </p>
          <p className="contact-item">
            <a
              className="lowercase"
              href="https://instagram.com/emiledrescher"
              target="_blank">
              @emiledrescher
            </a>
          </p>
        </div>
      </div>
    </StyledMobileMenu>
  )
}

export default MobileMenu

const StyledMobileMenu = styled.div`
  .menuContainer {
    background: rgba(255, 255, 255, 1);
    border-left: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    font-family: galvji;
    height: 100vh;
    left: 0;
    opacity: ${({ open }) => (open ? '1' : '0')};
    padding: 60px 15px;
    pointer-events: ${({ open }) => (open ? 'all' : 'none')};
    pointer-events: all;
    position: absolute;
    text-align: left;
    top: 0;
    transform: ${({ open }) =>
      open ? 'translateX(20vw)' : 'translateX(100vw)'};
    transition: all 0.3s ease-out;
    width: 80vw;
    text-transform: uppercase;

    a {
      top: 200px;
      font-size: 14px;
      padding: 5px 0;
      text-decoration: none;
      text-align: right;
      text-transform: uppercase;
      margin-top: 20px;

      &.active {
        font-style: italic;
      }
    }

    p.spacer {
      padding: 60px 0;
    }

    .filter-item {
      text-align: right;
      padding: 10px 0;

      a {
        font-size: 14px;
      }

      &.active a {
        color: black;
        font-style: italic;
      }
    }
  }

  .contact-group {
    margin-top: 120px;

    p,
    a {
      font-size: 14px;
      padding: 5px 0;
      text-decoration: none;
      text-align: right;
      text-transform: uppercase;
      margin-top: 0;
    }

    .contact-item {
      a {
        font-size: 12px;
      }
    }
  }

  .lowercase {
    text-transform: lowercase !important;
  }

  .closeMenuArea {
    display: none;
    height: 100vh;
    left: 0;
    pointer-events: all;
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.8);

    &.is-visible {
      display: block;
    }
  }
`
