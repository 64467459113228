import {
  createStorefrontApiClient,
  StorefrontApiClient,
} from '@shopify/storefront-api-client'

import {
  createCartItemMutation,
  createCartMutation,
  deleteCartItemsMutation,
  updateCartItemsMutation,
} from './mutations/cart.storefront'
import {
  normalizeCart,
  normalizeCollection,
  normalizeProduct,
} from './normalize'
import { getCartQuery } from './queries/cart.storefront'
import {
  getCollectionByIdQuery,
  getCollectionQuery,
  getCollectionsQuery,
} from './queries/collection.storefront'
import {
  getProductQuery,
  getProductsByHandleQuery,
  getProductsQuery,
  getProductVariantQuery,
} from './queries/product.storefront'

import {
  CollectionsQuery,
  CreateCartItemMutation,
  CreateCartMutation,
  DeleteCartItemsMutation,
  PagesQuery,
  ProductsByHandleQuery,
  ProductVariantByOptionsQuery,
  SingleCartQuery,
  SingleCollectionByIdQuery,
  SingleCollectionQuery,
  SinglePageQuery,
  SingleProductQuery,
  UpdateCartItemsMutation,
} from './types/storefront.generated'
import {
  PlatformCart,
  PlatformCollection,
  PlatformItemInput,
  PlatformPage,
  PlatformProduct,
} from './types'

import { cleanShopifyId, makeShopifyId } from './utils'

interface CreateShopifyClientProps {
  storeDomain: string
  storefrontAccessToken?: string
}

export function createShopifyClient({
  storefrontAccessToken,
  storeDomain,
}: CreateShopifyClientProps) {
  const client = createStorefrontApiClient({
    storeDomain,
    publicAccessToken: storefrontAccessToken,
    apiVersion: '2025-01',
    customFetchApi: (url, init) => fetch(url, init as never) as never,
  })

  // To prevent prettier from wrapping pretty one liners and making them unreadable
  // prettier-ignore
  return {
    getProduct: async (id: string) => getProduct(client!, id),
    getProductVariant: async (id: string,  selectedVariant: Record<string, string>) => getProductVariant(client!, id, selectedVariant),
    getProductByHandle: async (handle: string) => getProductByHandle(client!, handle),
    createCart: async (items: PlatformItemInput[]) => createCart(client!, items),
    createCartItem: async (cartId: string, items: PlatformItemInput[]) => createCartItem(client!,cartId, items),
    updateCartItem: async (cartId: string, items: PlatformItemInput[]) => updateCartItem(client!,cartId, items),
    deleteCartItem: async (cartId: string, itemIds: string[]) => deleteCartItem(client!, cartId, itemIds),
    getCart: async (cartId: string) => getCart(client!, cartId),
    getCollections: async (limit?: number) => getCollections(client!, limit),
    getCollection: async (handle: string) => getCollection(client!, handle),
    getCollectionById: async (id: string) => getCollectionById(client!, id),
    getAllProducts: async () => getAllProducts(client!),
    getAllCollections: async () => getAllCollections(client!),
  }
}

async function getProduct(
  client: StorefrontApiClient,
  id: string,
): Promise<PlatformProduct | null> {
  const response = await client.request<SingleProductQuery>(getProductQuery, {
    variables: { id: makeShopifyId(id, 'Product') },
  })
  const product = response.data?.product

  return normalizeProduct(product)
}

async function getProductVariant(
  client: StorefrontApiClient,
  id: string,
  selectedVariants: Record<string, string>,
): Promise<unknown | null> {
  const selectedOptions = Object.entries(selectedVariants).map(
    ([name, value]) => ({
      name,
      value,
    }),
  )

  const response = await client.request<ProductVariantByOptionsQuery>(
    getProductVariantQuery,
    {
      variables: {
        productId: id,
        selectedOptions: selectedOptions.length > 0 ? selectedOptions : [],
      },
    },
  )

  return response.data?.product
}

async function getProductByHandle(client: StorefrontApiClient, handle: string) {
  const response = await client.request<ProductsByHandleQuery>(
    getProductsByHandleQuery,
    { variables: { query: `'${handle}'` } },
  )
  const product = response.data?.products?.edges?.find(Boolean)?.node

  return normalizeProduct(product)
}

async function createCart(
  client: StorefrontApiClient,
  items: PlatformItemInput[],
): Promise<PlatformCart | undefined | null> {
  const cart = await client.request<CreateCartMutation>(createCartMutation, {
    variables: { items },
  })

  return normalizeCart(cart.data?.cartCreate?.cart)
}

async function createCartItem(
  client: StorefrontApiClient,
  cartId: string,
  items: PlatformItemInput[],
): Promise<PlatformCart | undefined | null> {
  const cart = await client.request<CreateCartItemMutation>(
    createCartItemMutation,
    { variables: { cartId, items } },
  )

  return normalizeCart(cart.data?.cartLinesAdd?.cart)
}

async function updateCartItem(
  client: StorefrontApiClient,
  cartId: string,
  items: PlatformItemInput[],
): Promise<PlatformCart | undefined | null> {
  const cart = await client.request<UpdateCartItemsMutation>(
    updateCartItemsMutation,
    { variables: { cartId, items } },
  )

  return normalizeCart(cart.data?.cartLinesUpdate?.cart)
}

async function deleteCartItem(
  client: StorefrontApiClient,
  cartId: string,
  itemIds: string[],
): Promise<PlatformCart | undefined | null> {
  const cart = await client.request<DeleteCartItemsMutation>(
    deleteCartItemsMutation,
    { variables: { itemIds, cartId } },
  )

  return normalizeCart(cart.data?.cartLinesRemove?.cart)
}

async function getCart(
  client: StorefrontApiClient,
  cartId: string,
): Promise<PlatformCart | undefined | null> {
  const cart = await client.request<SingleCartQuery>(getCartQuery, {
    variables: { cartId },
  })

  return normalizeCart(cart.data?.cart)
}

async function getCollections(
  client: StorefrontApiClient,
  limit?: number,
): Promise<PlatformCollection[] | undefined | null> {
  const collections = await client.request<CollectionsQuery>(
    getCollectionsQuery,
    { variables: { limit: limit || 250 } },
  )

  return collections.data?.collections.edges
    .map((collection) => normalizeCollection(collection.node))
    .filter(Boolean) as PlatformCollection[]
}

async function getCollection(
  client: StorefrontApiClient,
  handle: string,
): Promise<PlatformCollection | undefined | null> {
  const collection = await client.request<SingleCollectionQuery>(
    getCollectionQuery,
    { variables: { handle } },
  )

  return normalizeCollection(collection.data?.collection)
}

async function getCollectionById(
  client: StorefrontApiClient,
  id: string,
): Promise<PlatformCollection | undefined | null> {
  const collection = await client.request<SingleCollectionByIdQuery>(
    getCollectionByIdQuery,
    { variables: { id: makeShopifyId(id, 'Collection') } },
  )

  return normalizeCollection(collection.data?.collection)
}

async function getAllProducts(
  client: StorefrontApiClient,
  limit: number = 250,
): Promise<PlatformProduct[]> {
  const products: PlatformProduct[] = []
  let hasNextPage = true
  let cursor: string | null = null

  while (hasNextPage) {
    const response = await client.request(getProductsQuery, {
      variables: { numProducts: limit, cursor },
    })

    const fetchedProducts = response.data?.products?.edges || []
    products.push(...fetchedProducts.map((edge) => normalizeProduct(edge.node)))

    hasNextPage = response.data?.products?.pageInfo?.hasNextPage || false
    cursor = hasNextPage ? response.data?.products?.pageInfo?.endCursor : null
  }

  return products.map((product) => ({
    ...product,
    id: cleanShopifyId(product.id, 'Product'),
  }))
}

async function getAllCollections(client: StorefrontApiClient, limit?: number) {
  const collections: PlatformCollection[] = []
  let hasNextPage = true
  let cursor: string | null = null

  while (hasNextPage) {
    const response = await client.request(getCollectionsQuery, {
      variables: { first: limit, after: cursor },
    })
    const fetchedCollections = response.data?.collections?.edges || []
    collections.push(
      ...fetchedCollections.map((edge) => normalizeCollection(edge.node)),
    )

    hasNextPage = response.data?.collections?.pageInfo?.hasNextPage || false
    cursor = hasNextPage
      ? response?.data?.collections?.pageInfo?.endCursor
      : null
  }

  return collections.map((collection) => ({
    ...collection,
    id: cleanShopifyId(collection.id, 'Collection'),
  }))
}

export const storefrontClient = createShopifyClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL || '',
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || '',
})
