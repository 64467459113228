import { storefrontClient } from './client'
import type { PlatformItemInput } from './types'

export const getProduct = async (id: string) =>
  await storefrontClient.getProduct(id)

export const getProductVariant = async (
  id: string,
  selectedVariant: Record<string, string>,
) => await storefrontClient.getProductVariant(id, selectedVariant)

export const getProductByHandle = async (handle: string) =>
  await storefrontClient.getProductByHandle(handle)

export const getCart = async (cartId: string) =>
  await storefrontClient.getCart(cartId)

export const getCollection = async (id: string) =>
  await storefrontClient.getCollectionById(id)

export const createCart = async () => await storefrontClient.createCart([])

export const createCartItem = async (
  cartId: string,
  items: PlatformItemInput[],
) => await storefrontClient.createCartItem(cartId, items)

export const deleteCartItem = async (cartId: string, itemIds: string[]) =>
  await storefrontClient.deleteCartItem(cartId, itemIds)

export const updateCartItem = async (
  cartId: string,
  items: PlatformItemInput[],
) => await storefrontClient.updateCartItem(cartId, items)
