import React from 'react'
import styled from 'styled-components'

const MobileMenuButton = ({ open, setOpen }) => {
  return (
    <StyledMobileMenuButton
      open={open}
      className={open ? 'open' : ''}
      onClick={() => setOpen(!open)}>
      <label></label>
      {/* {open ? "" : "Menu"} */}
      <div className="cross"></div>
    </StyledMobileMenuButton>
  )
}

export default MobileMenuButton

const StyledMobileMenuButton = styled.div`
background: transparent;
border: none;
color: black;
cursor: pointer;
display: flex;
flex-direction: column;
font-size: 14px;
height: 50px;
justify-content: center;
padding: 0;
pointer-events: all;
position: absolute;
right: 10px;
text-align: right;
top: 0;
width: 40px;
z-index: 10;
text-transform: uppercase;


>  label {
  width: 19px;
  height: 13px;
  right: 6px;
  position: absolute;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
  &:before {
    content: '';
    display: block;
    position: relative;
    top: 5px;
    border-top: 1px solid #000;
  }
}

&:focus {
  outline: none;
}


&.open {
>  label {
  display: none;
}
  &:before, &:after {
    position: absolute;
    right: 15px;
    top: 15px;
    content: ' ';
    height: 20px;
    width: 1px;
    background-color: black;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
}
`
